import React, { useState, useEffect } from 'react';
import getAllTables from "../../Api/tables";
import getTableById from "../../Api/table";

const TableComponent = ({ productId }) => {
    const [allTablesData, setAllTablesData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const allTablesResponse = await getAllTables();
                const allTables = allTablesResponse.data;


                const parsedProductId = typeof productId === 'string' ? parseInt(productId, 10) : productId;


                const filteredTables = allTables.filter(table => table.product_id === parsedProductId);

                const tablesDataPromises = filteredTables.map(async (table) => {
                    const tableDataResponse = await getTableById(table.table_id);
                    return tableDataResponse.data;
                });


                const tablesData = await Promise.all(tablesDataPromises);
                setAllTablesData(tablesData);
            } catch (error) {
                console.error('Error fetching table data:', error);
            }
        };

        fetchData();
    }, [productId]);

    return (
        <div className="row justify-content-center">
            {allTablesData.map((tableData, index) => (
                <div className="tabcont" key={index}>
                    <div className="tablehead">
                        <h3>{tableData.name}</h3>
                    </div>
                    <table className="styled-table">
                        <thead>
                            <tr>
                                {tableData.columns.map((column) => (
                                    <th key={column.column_id} style={{ width: `${column.column_width}px` }}>{column.column_name}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.rows.map((row) => (
                                <tr key={row.row_id} style={{ height: `${row.row_height}px` }}>
                                    {tableData.cells
                                        .filter((cell) => cell.row_id === row.row_id)
                                        .map((cell) => (
                                            <td
                                                key={cell.column_id}
                                                rowSpan={cell.row_span}
                                                colSpan={cell.col_span}
                                                style={{ padding: '8px', border: '1px solid #ddd' }}
                                            >
                                                {cell.cell_content === '' ? <>&nbsp;</> : cell.cell_content}
                                            </td>
                                        ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ))}
        </div>
    );
};

export default TableComponent;
