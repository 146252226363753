import React from "react";
import HeaderThree from "../Layouts/Headers/HeaderThree";
import InnerPageTitle from "../Helpers/InnerPageTitle";
import FooterOne from "../Layouts/Footers/FooterOne";
import NewServiceArea from "./NewServiceArea";
// import FeatureServiceArea from "./FeatureServiceArea";
// import TestimonialArea from "../HomeOne/TestimonialArea";
// import ServiceArea from "../HomeOne/ServiceArea";
// import MultipleArea from "./MultipleArea";

function Services() {
  return (
    <>
      <HeaderThree />
      <main>

        {/* <MultipleArea /> */}

        <InnerPageTitle
          title="Services"
          paths={[
            { name: "Home", path: "/" },
            { name: "Services", path: "/services" },
          ]}
        />

        {/* <TestimonialArea /> */}


        <NewServiceArea className="inner-services-area pt-115 pb-90" />

      </main>
      <FooterOne />
    </>
  );
}

export default Services;
