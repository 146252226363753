import React from "react";

function WorkArea() {
  return (
    <section className="work-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-title text-center mb-50">
              <span className="sub-title">Working Process</span>
              <h2 className="title">Our Working Process</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-3 col-md-6">
            <div className="work-item">
              <div className="work-thumb">
                <img
                  src={require(`../../assets/img/images/latest.png`)}
                  alt="Initial Consultation"
                />
                <h4 className="number">01</h4>
              </div>
              <div className="work-content">
                <h2 className="title">Initial Consultation</h2>
                <p>
                  We begin by understanding your goals through consultation. We tailor our services to fit your needs.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div className="work-item">
              <div className="work-thumb">
                <img
                  src={require(`../../assets/img/images/latest.png`)}
                  alt="Planning and Strategy"
                />
                <h4 className="number">02</h4>
              </div>
              <div className="work-content">
                <h2 className="title">Planning and Strategy</h2>
                <p>
                  Our team develops a detailed plan. We allocate resources to meet your objectives effectively.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div className="work-item">
              <div className="work-thumb">
                <img
                  src={require(`../../assets/img/images/latest.png`)}
                  alt="Implementation"
                />
                <h4 className="number">03</h4>
              </div>
              <div className="work-content">
                <h2 className="title">Implementation</h2>
                <p>
                  We execute the plan precisely. Ensuring all aspects are handled and meet high quality standards.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6">
            <div className="work-item">
              <div className="work-thumb">
                <img
                  src={require(`../../assets/img/images/latest.png`)}
                  alt="Review and Delivery"
                />
                <h4 className="number">04</h4>
              </div>
              <div className="work-content">
                <h2 className="title">Review and Delivery</h2>
                <p>
                  We review thoroughly to ensure objectives are met. The product is delivered complete and ready for use.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WorkArea;
