import React, { useEffect, useState } from "react";

function CounterUpCom({ endValue = 0, duration = 2 }) {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const increment = endValue / (duration * 1000 / 10);
    const intervalTime = 10; 

    const id = setInterval(() => {
      setCount((prev) => {
        const nextValue = prev + increment;
        if (nextValue >= endValue) {
          clearInterval(id);
          return endValue;
        }
        return nextValue;
      });
    }, intervalTime);

    return () => {
      clearInterval(id);
    };
  }, [endValue, duration]);

  return <span>{Math.floor(count)}</span>;
}

export default CounterUpCom;
