import React, { useEffect } from "react";
import SliderCom from "../Helpers/SliderCom";

function Hero() {
  const sliderSettins = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  useEffect(() => {
    const getAllTooltip = document.querySelectorAll(".tooltip-item");
    getAllTooltip.forEach((item) => {
      item.querySelector(".tooltip-btn").addEventListener("click", () => {
        if (item.classList.contains("active")) {
          item.classList.remove("active");
        } else {
          item.classList.add("active");
        }
      });
    });
  });
  return (
    <section className="banner-area">
      <div
        className="banner-shape"
        style={{
          background: `url(${require(`../../assets/img/banner/new.jpg`)})`,
        }}
      ></div>
      <div
        className="banner-bg"
        style={{
          background: `url(${require(`../../assets/img/download/footer-bg.jpg`)})`,
        }}
      >
        <div className="banner-content">
          <h2 className="title wow fadeInDown" data-wow-delay=".2s">
            Welcome to Miller & Bauer International
          </h2>
          <p className="wow fadeInUp" data-wow-delay=".2s">
            Your global partner for top-tier industrial solutions! As a Government of India Recognized Star Export House, we specialize in manufacturing and exporting a wide range of high-quality hose couplings, fittings, accessories, dewatering pumps, and rubber-related products.
          </p>
          <a href="/about" className="btn wow fadeInUp" data-wow-delay=".4s">
            Discover More
          </a>
        </div>
      </div>

      <div className="brand-area">
        <div className="container">
          <div className="brand-inner">
            <div className="brand-active row position-relative">
              <SliderCom settings={sliderSettins}>



                {/* <div className="col-12">
                  <div className="brand-item">
                    <img className="bimm"
                      src={require(`../../assets/img/download/New folder/PPI-01.png`)}
                      alt=""
                    />
                  </div>
                </div>


                <div className="col-12">
                  <div className="brand-item">
                    <img className="bimm"
                      src={require(`../../assets/img/download/logo_03.png`)}
                      alt=""
                    />
                  </div>
                </div> */}




                {/* <div className="col-12">
                  <div className="brand-item">
                    <img className="bimm"
                      src={require(`../../assets/img/download/KSA-01.png`)}
                      alt=""
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="brand-item">
                    <img className="bimm"
                      src={require(`../../assets/img/download/UAE-01.png`)}
                      alt=""
                    />
                  </div>
                </div> */}

              </SliderCom>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
