import axios from 'axios';
import API_BASE_URL from './config';


const auth = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',

    },
});


const getTableById = async (id) => {
    try {
        const response = await auth.get(`/table?${id}`);
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 404) {
            console.log(`Table with ID ${id} not found`);
        }
        throw error;
    }
};


export default getTableById;


