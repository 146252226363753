import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import postNewsletter from "../../Api/newsletter";
import abtUs from "../../Api/about";


function FooterOne() {




    const [email, setEmail] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [message, setMessage] = useState('');

    const [abt, setAbt] = useState('');

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');


    const currentYear = new Date().getFullYear();

    const submitEmail = async (event) => {
        event.preventDefault();
        setSuccessMessage('');
        setErrorMessage('');

        try {
            const response = await postNewsletter({ email });

            if (response.errors && response.errors.length > 0) {
                console.error('Server validation errors: ', response.errors);
                setErrorMessage('Validation errors occurred. Please try again.');
            } else {
                setEmail('');
                setSuccessMessage('Successfully subscribed to the newsletter!');

                setTimeout(() => {
                    setSuccessMessage('');
                }, 3000);

                console.log(response);
            }
        } catch (error) {
            console.error('Error subscribing to newsletter:', error.message);
            setErrorMessage('Error subscribing to the newsletter. Please try again later.');

            setTimeout(() => {
                setErrorMessage('');
            }, 3000);
        }
    };


    useEffect(() => {

        const abtUsData = async () => {

            try {

                const response = await abtUs();

                const abtData = response.data;
                setAbt(abtData);
            } catch (error) {
                console.error('Error fetching about us data:', error.message);

            }
        };

        abtUsData();
    }, []);

    return (
        <footer>
            <div
                className="footer-area footer-bg"
                style={{ backgroundImage: `url(${require("../../../assets/img/bg/footer_bg.jpg")})` }}
            >
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-7">
                                <div className="footer-widget">
                                    <h2 className="fw-title">Contact Us</h2>
                                    <div className="footer-content">
                                        <p>
                                            Miller and Bauer International,<br />
                                            155, Venus House, 2nd Floor,<br />
                                            Street No.9, Jeevan Nagar , <br />
                                            Focal Point , Ludhiana
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-5 col-sm-6">
                                <div className="footer-widget">
                                    <h2 className="fw-title">Our Products</h2>
                                    <div className="footer-link">
                                        <ul className="list-wrap">
                                            <li><Link to="/product"><i className="fas fa-angle-double-right"></i>Dewatering Pump</Link></li>
                                            <li><Link to="/product"><i className="fas fa-angle-double-right"></i>Dewatering Equipments</Link></li>
                                            <li><Link to="/product"><i className="fas fa-angle-double-right"></i>Submersible Pump</Link></li>
                                            <li><Link to="/product"><i className="fas fa-angle-double-right"></i>Trash Pumps</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                                <div className="footer-widget">
                                    <h2 className="fw-title">Quick Links</h2>
                                    <div className="footer-link">
                                        <ul className="list-wrap">
                                            <li><Link to="/about"><i className="fas fa-angle-double-right"></i>About Us</Link></li>
                                            <li><Link to="/services"><i className="fas fa-angle-double-right"></i> Services</Link></li>
                                            <li><Link to="/contact"><i className="fas fa-angle-double-right"></i> Contact Us</Link></li>

                                            {/* <li><Link to="/logicfusion"><i className="fas fa-angle-double-right"></i>Logic Fusion Group </Link></li> */}

                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-7">
                                <div className="footer-widget">
                                    <h2 className="fw-title">Newsletter </h2>
                                    <div className="footer-content">
                                        <div className="footer-newsletter">
                                            <h4 className="title">Subscribe to Our Newsletter</h4>
                                            <form onSubmit={submitEmail}>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    placeholder="Enter your email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />

                                                {successMessage && <div className="success">{successMessage}</div>}
                                                {errorMessage && <div className="error">{errorMessage}</div>}

                                                <button type="submit" className="btn btn-two">Subscribe</button>
                                            </form>
                                            {message && <p>{message}</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer-logo-area">
                            <div className="row align-items-center">
                                <div className="col-xl-3 col-lg-3 col-md-12">
                                    <div className="logo">
                                        <a href="/">
                                            <img
                                                src={require(`../../../assets/img/download/logo.png`)}
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                </div>


                                <div className="col-xl-4 col-lg-4 col-md-6">
                                    <a href={`callto:${abt.phone}`}>
                                        <div className="footer-contact">
                                            <div className="icon">
                                                <i className="fas fa-phone-alt"></i>
                                            </div>
                                            <div className="content">
                                                <span>Phone No</span>

                                                <a>{abt.phone}</a>
                                            </div>
                                        </div>
                                    </a>
                                </div>



                                <div className="col-xl-5 col-lg-5 col-md-6">
                                    <div className="footer-social">
                                        <h2 className="title">Follow Us:</h2>
                                        <ul className="list-wrap">
                                            <li><Link to="#"><i className="fab fa-facebook-f"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-linkedin-in"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-youtube"></i></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="fotw container">
                        <div className="fotr row">
                            <div className="fotm col-md-12">
                                <div className="copyright-text">

                                    <div className="fop">
                                        Copyright © {currentYear} Miller and Bauer International | Developed By
                                        <a href="https://www.weberfox.com/" rel="noreferrer" target="_blank">&nbsp;WeberFox Technologies Pvt Ltd</a>
                                    </div>




                                </div>
                            </div>
                            {/* <div className="col-md-6">
                                <div className="footer-bootom-menu">
                                    <ul className="list-wrap">
                                        <li><a href="/contact">Privacy Policy</a></li>
                                        <li><a href="/contact">Terms & Conditions</a></li>
                                    </ul>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default FooterOne;
