import React, { useState, useEffect } from "react";
import quote from "../Api/quote";
import getProducts from "../Api/product";
import CounterUpCom from "../Helpers/CounterUpCom";
import Select from "react-select";

function Quote() {
    const counterDuration = 3;
    const [formData, setFormData] = useState({
        fname: "",
        lname: "",
        email: "",
        phone: "",
        products: [],
        address: "",
        message: ""
    });

    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState("");
    const [products, setProducts] = useState([]);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await getProducts();
                const productOptions = response.data.map((product) => ({
                    value: product.id,
                    label: product.name,
                }));
                setProducts(productOptions);
            } catch (error) {
                console.error("Failed to fetch products", error);
            }
        };

        fetchProducts();
    }, []);

    const validate = () => {
        const newErrors = {};
        if (!formData.fname) newErrors.fname = "First name is required";
        if (!formData.lname) newErrors.lname = "Last name is required";
        if (!formData.email) newErrors.email = "Email is required";
        else if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = "Email is invalid";
        if (!formData.phone) newErrors.phone = "Mobile number is required";
        else if (!/^\d{10}$/.test(formData.phone)) newErrors.phone = "Mobile number is invalid";
        if (!formData.address) newErrors.address = "Address is required";
        if (!formData.products || formData.products.length === 0) newErrors.products = "At least one product is required";
        if (!formData.message) newErrors.message = "Message is required";
        return newErrors;
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleProductsChange = (selectedOptions) => {
        const selectedProducts = selectedOptions ? selectedOptions.map(option => option.value) : [];
        setFormData((prevState) => ({
            ...prevState,
            products: selectedProducts
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const validationErrors = validate();
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            try {
                const response = await quote({
                    fname: formData.fname,
                    lname: formData.lname,
                    email: formData.email,
                    phone: formData.phone,
                    products: formData.products,
                    address: formData.address,
                    message: formData.message
                });

                setSuccessMessage("Your message has been sent successfully!");
                setFormData({
                    fname: "",
                    lname: "",
                    email: "",
                    phone: "",
                    products: [],
                    address: "",
                    message: ""
                });

                setTimeout(() => {
                    setSuccessMessage("");
                }, 3000);

                console.log(response);
            } catch (error) {
                setErrors({ submit: "Failed to send message." });
                setTimeout(() => {
                    setErrors({});
                }, 3000);
                console.error(error);
            }
        }
    };

    return (
        <div className="area-bg">
            <div
                className="area-background-img jarallax"
                style={{
                    backgroundImage: `url(${require("../../assets/img/download/02.jpg")})`,
                }}
            ></div>






            <section className="appointment-area pt-15 pb-100 ">
                <div id="quote" className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title white-title mb-60">
                                <span className="sub-title">
                                    Connect with us regarding your Enquiries
                                </span>
                                <h2 className="title">Request A Quote</h2>
                            </div>
                        </div>
                    </div>
                    <div
                        className="appointment-inner"
                        style={{
                            backgroundImage: `url(${require("../../assets/img/bg/appointment_bg.jpg")})`,
                        }}
                    >
                        <div className="a-form row">
                            <div className="col-xl-12">
                                <div className="appointment-form">
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-grp">
                                                    <input
                                                        id="fname"
                                                        type="text"
                                                        placeholder="First Name"
                                                        value={formData.fname}
                                                        onChange={handleChange}
                                                    />
                                                    <label htmlFor="fname">
                                                        <i className="fas fa-user"></i>
                                                    </label>
                                                    {errors.fname && <small className="error">{errors.fname}</small>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-grp">
                                                    <input
                                                        id="lname"
                                                        type="text"
                                                        placeholder="Last Name*"
                                                        value={formData.lname}
                                                        onChange={handleChange}
                                                    />
                                                    <label htmlFor="lname">
                                                        <i className="fas fa-user"></i>
                                                    </label>
                                                    {errors.lname && <small className="error">{errors.lname}</small>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-grp">
                                                    <input
                                                        id="phone"
                                                        type="text"
                                                        placeholder="Phone Number*"
                                                        value={formData.phone}
                                                        onChange={handleChange}
                                                    />
                                                    <label htmlFor="phone">
                                                        <i className="fas fa-phone-alt"></i>
                                                    </label>
                                                    {errors.phone && <small className="error">{errors.phone}</small>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-grp">
                                                    <input
                                                        id="email"
                                                        type="email"
                                                        placeholder="Email Address*"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                    />
                                                    <label htmlFor="email">
                                                        <i className="fas fa-envelope"></i>
                                                    </label>
                                                    {errors.email && <small className="error">{errors.email}</small>}
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-grp">
                                                    <input
                                                        id="address"
                                                        type="text"
                                                        placeholder="Address"
                                                        value={formData.address}
                                                        onChange={handleChange}
                                                    />
                                                    <label htmlFor="address">
                                                        <i className="fas fa-map-marker-alt"></i>
                                                    </label>
                                                    {errors.address && <small className="error">{errors.address}</small>}
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-grp">
                                                    <Select
                                                        id="products"
                                                        isMulti
                                                        options={products}
                                                        onChange={handleProductsChange}
                                                        value={products.filter(product => formData.products.includes(product.value))}
                                                        classNamePrefix="react-select"
                                                        placeholder="Select Products"
                                                    />
                                                    {errors.products && <small className="error">{errors.products}</small>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-grp">
                                            <textarea
                                                id="message"
                                                placeholder="Your Message here"
                                                value={formData.message}
                                                onChange={handleChange}
                                            ></textarea>
                                            {errors.message && <small className="error">{errors.message}</small>}
                                        </div>
                                        <button type="submit" className="btn">
                                            Request Submit Now
                                        </button>
                                        {errors.submit && <div className="error">{errors.submit}</div>}
                                        {successMessage && <div className="success">{successMessage}</div>}
                                    </form>
                                </div>
                            </div>
                            {/* <div className="col-xl-5">
                <div className="appointment-img">
                  <img className = "appimg"
                    src={require(`../../assets/img/download/ab.jpg`)}
                    alt=""
                  />
                </div>
              </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Quote;
