import React from "react";
import { Link } from "react-router-dom";
import SliderCom from "../Helpers/SliderCom";

function NewServiceArea() {
  const settingSlider = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div
      className="area-bg-four"
      style={{
        backgroundImage: `url(${require("../../assets/img/bg/area_bg04.jpg")})`,
      }}
    >
      <section className="project-area-four">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-title text-center mb-60">
                <span className="sub-title">Our Services</span>
                <h2 className="title">Explore Our Services</h2>
              </div>
            </div>
          </div>
          <div className="row project-active-two">
            <SliderCom settings={settingSlider}>
              <div className="col-lg-4">
                <div className="project-item-four">
                  <div className="project-thumb-four">
                    <Link to="/services/services-details">
                      <img
                        src={require(`../../assets/img/download/gwc.jpg`)}
                        alt="Wellpoint Dewatering"
                      />
                    </Link>
                  </div>
                  <div className="project-content-four">
                    <div className="content-left">
                      <h2 className="title">
                        <Link to="/services/services-details">Wellpoint Dewatering</Link>
                      </h2>
                      <ul className="list-wrap">
                        <li>
                          <Link to="/services/services-details">Shallow</Link>
                        </li>
                        <li>
                          <Link to="/services/services-details">Quick</Link>
                        </li>
                      </ul>
                    </div>
                    <div className="content-right">
                      <Link to="/services/services-details" className="link-btn">
                        <i className="fas fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="project-item-four">
                  <div className="project-thumb-four">
                    <Link to="/services/services-details">
                      <img
                        src={require(`../../assets/img/download/well.jpg`)}
                        alt="Deep Well Dewatering"
                      />
                    </Link>
                  </div>
                  <div className="project-content-four">
                    <div className="content-left">
                      <h2 className="title">
                        <Link to="/services/services-details">Deep Well Dewatering</Link>
                      </h2>
                      <ul className="list-wrap">
                        <li>
                          <Link to="/services/services-details">Deep</Link>
                        </li>
                        <li>
                          <Link to="/services/services-details">Volume</Link>
                        </li>
                      </ul>
                    </div>
                    <div className="content-right">
                      <Link to="/services/services-details" className="link-btn">
                        <i className="fas fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="project-item-four">
                  <div className="project-thumb-four">
                    <Link to="/services/services-details">
                      <img
                        src={require(`../../assets/img/download/sump.jpg`)}
                        alt="Sump Pumping"
                      />
                    </Link>
                  </div>
                  <div className="project-content-four">
                    <div className="content-left">
                      <h2 className="title">
                        <Link to="/services/services-details">Sump Pumping</Link>
                      </h2>
                      <ul className="list-wrap">
                        <li>
                          <Link to="/services/services-details">Simple</Link>
                        </li>
                        <li>
                          <Link to="/services/services-details">Cost</Link>
                        </li>
                      </ul>
                    </div>
                    <div className="content-right">
                      <Link to="/services/services-details" className="link-btn">
                        <i className="fas fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="project-item-four">
                  <div className="project-thumb-four">
                    <Link to="/services/services-details">
                      <img
                        src={require(`../../assets/img/download/sub.jpg`)}
                        alt="Vacuum Assisted Dewatering"
                      />
                    </Link>
                  </div>
                  <div className="project-content-four">
                    <div className="content-left">
                      <h2 className="title">
                        <Link to="/services/services-details">Submersible Pumping</Link>
                      </h2>
                      <ul className="list-wrap">
                        <li>
                          <Link to="/services/services-details">Enhanced</Link>
                        </li>
                        <li>
                          <Link to="/services/services-details">Challenging</Link>
                        </li>
                      </ul>
                    </div>
                    <div className="content-right">
                      <Link to="/services/services-details" className="link-btn">
                        <i className="fas fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </SliderCom>
          </div>
        </div>
      </section>
    </div>
  );
}

export default NewServiceArea;
