import React from "react";

function HistoryArea({ videoHandler }) {
  return (
    <section className="history-area pt-70 pb-70">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="history-img-wrap">
              <ul className="list-wrap">
                <li>
                  <img
                    src={require(`../../assets/img/download/ntea.jpg`)}
                    alt=""
                  />
                </li>

              </ul>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="history-content">
              <div className="section-title mb-20">
                <span className="sub-title"> Company History</span>
                <h2 className="title">
                  Our Legacy
                </h2>
              </div>
              <p>
                Our team, led by highly qualified and experienced promoters with over 20 years in similar industries, ensures that our products meet and exceed international standards. We proudly export to numerous countries worldwide, including the USA, UK, Canada, Australia, and throughout the Middle East. Our commitment to quality and customer satisfaction has earned us a reputation for excellence and reliability in all our markets
              </p>
              <div className="history-list">
                <ul className="list-wrap">
                  <li>
                    <i className="fas fa-check-circle"></i>Technology management
                  </li>
                  <li>
                    <i className="fas fa-check-circle"></i>Efficient Solutions
                  </li>
                  <li>
                    <i className="fas fa-check-circle"></i>Quality products

                  </li>
                  <li>
                    <i className="fas fa-check-circle"></i>Quick Response
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HistoryArea;
