import React from "react";
import { Link } from "react-router-dom";

function MainWid() {
  return (
    <section className="services-details-area pt-70 pb-10">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-8">
            <div className="services-details-wrap">
              <div className="services-details-thumb">
                <img
                  src={require(`../../../assets/img/download/nser.png`)}
                  alt=""
                />
              </div>



             
            </div>


          </div>






          <div className="col-xl-4 col-lg-6">
            <aside className="services-sidebar">
              <div className="services-widget">
                <h4 className="widget-title">Other Services</h4>
                <div className="our-services-list">
                  <ul className="list-wrap">
                    <li>
                      <Link to="/services">
                      Deep Well Dewatering
                        <i className="fas fa-arrow-right"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/services">
                      Wellpoint Dewatering
                        <i className="fas fa-arrow-right"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/services">
                      Sump Pumping
                        <i className="fas fa-arrow-right"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            


             </aside>
             </div>
            </div>
            </div>
            


            

            <div className="container pt-50 pb-70 ">
              <div className="row justify-content-center">



             
              <div className="col-lg-8">





              <div className="services-details-content">
              <h2 className="title">
  Innovative Dewatering Solutions
</h2>
<p>
  Our Dewatering Solutions service offers comprehensive planning, implementation, and management of groundwater and surface water control for various projects. From initial concept to successful completion, our experienced team collaborates closely with clients to deliver effective and sustainable dewatering strategies customized to their unique needs.
</p>
                <div className="services-process-wrap">
                  <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-8">
                      <div className="services-process-img">
                        <img
                          src={require(`../../../assets/img/download/nj.jpg`)}
                          alt=""
                        />
                        <img
                          src={require(`../../../assets/img/download/wo.avif`)}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

   </div>




                     <div className="col-lg-4">
                      <div className="services-process-content">
                        <h2 className="widget-title">Our Service Process</h2>
                        <ul className="list-wrap">
                          <li>
                            <div className="services-process-item">
                              <div className="icon">
                                <img
                                  src={
                                    require(`../../../assets/img/icon/sp_icon01.svg`)
                                      .default
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="content">
                                <h4 className="title">Creative Analysis</h4>
                                <p>
                                Our team conducts thorough analysis, developing creative solutions to meet objectives.
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="services-process-item">
                              <div className="icon">
                                <img
                                  src={
                                    require(`../../../assets/img/icon/sp_icon02.svg`)
                                      .default
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="content">
                                <h4 className="title">Design</h4>
                                <p>
                                We use latest tools for detailed engineering designs, optimizing performance.
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="services-process-item">
                              <div className="icon">
                                <img
                                  src={
                                    require(`../../../assets/img/icon/sp_icon01.svg`)
                                      .default
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="content">
                                <h4 className="title">
                                  Development & Delivery
                                </h4>
                                <p>
                                Our project management ensures quality, cost efficiency, and timely completion.
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

             </div> 
               
     
      </div>
    </section>
  );
}

export default MainWid;
