import React from "react";
import HeaderThree from "../../Layouts/Headers/HeaderThree";
import FooterOne from "../../Layouts/Footers/FooterOne";
import MainWid from "./MainWid";
import BrandAreaWid from "../../Common/BrandAreaWid";
// import InnerPageTitle from "../../Helpers/InnerPageTitle";

function ProjectDetails() {
  return (
    <>
      <HeaderThree />
      
      {/* <InnerPageTitle
        title="Product Details"
        paths={[
          { name: "Home", path: "/" },
          { name: "Product Details", path: "/product-details" },
        ]}
      /> */}
      <MainWid />
      <BrandAreaWid className="pt-0" />
      <FooterOne />
    </>
  );
}

export default ProjectDetails;
