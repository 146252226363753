import axios from 'axios';
import API_BASE_URL from "./config";

const auth = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded', 
    }
});

const postNewsletter = async (data) => {
    try {
        const formData = new URLSearchParams();
        formData.append('email', data.email);

        const response = await auth.post('/newsletter', formData);
        return response.data;
    } catch (error) {
        if (error.response && (error.response.status === 400 || error.response.status === 401)) {
            console.log(error.response);
        }
        throw error;
    }
};

export default postNewsletter;
