import React from "react";

import { Link } from "react-router-dom";

function ServiceArea({ className }) {
  return (
    <section className={className || ""}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="section-title text-center mb-50 tg-heading-subheading animation-style3">
              <span className="sub-title tg-element-title">What We Do</span>
              <h2 className="title tg-element-title">Our Service Areas</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 col-sm-10">
            <Link to={`/services/services-details`}>
              <div
                className="services-item wow fadeInUp"
                data-wow-delay=".2s"
                style={{
                  background: `url("../../assets/img/services/services_item01.jpg")`,
                }}
              >
                <div className="services-icon">
                  <img
                    src={
                      require(`../../assets/img/icon/services_icon01.svg`).default
                    }
                    alt=""
                  />
                </div>
                <div className="services-content">
                  <h2 className="title">
                    <a href="/services/services-details">Groundwater Control</a>
                  </h2>
                  <h2 className="number">01</h2>
                </div>
                <div className="services-overlay-content">
                  <h2 className="title">
                    <a href="/services/services-details">Groundwater Control</a>
                  </h2>
                  <p>
                    Our groundwater control service provides effective management
                    of groundwater on construction sites to ensure dry, safe
                    working conditions.
                  </p>
                  <a href="/services/services-details" className="read-more">
                    Read More <i className="fas fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </Link>
          </div>


          <div className="col-lg-4 col-md-6 col-sm-10">
            <Link to={`/services/services-details`}>
              <div
                className="services-item wow fadeInUp"
                data-wow-delay=".4s"
                style={{
                  background: `url("../../assets/img/services/services_item02.jpg")`,
                }}
              >
                <div className="services-icon">
                  <img
                    src={
                      require(`../../assets/img/icon/services_icon02.svg`).default
                    }
                    alt=""
                  />
                </div>
                <div className="services-content">
                  <h2 className="title">
                    <a href="/services/services-details">Sludge Dewatering</a>
                  </h2>
                  <h2 className="number">02</h2>
                </div>
                <div className="services-overlay-content">
                  <h2 className="title">
                    <a href="/services/services-details">Sludge Dewatering</a>
                  </h2>
                  <p>
                    Our sludge dewatering service efficiently removes water from
                    sludge, reducing its volume and making disposal easier and
                    more cost-effective.
                  </p>
                  <a href="/services/services-details" className="read-more">
                    Read More <i className="fas fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </Link>
          </div>


          <div className="col-lg-4 col-md-6 col-sm-10">
            <Link to={`/services/services-details`}>
              <div
                className="services-item wow fadeInUp"
                data-wow-delay=".6s"
                style={{
                  background: `url("../../assets/img/services/services_item03.jpg")`,
                }}
              >
                <div className="services-icon">
                  <img
                    src={
                      require(`../../assets/img/icon/services_icon03.svg`).default
                    }
                    alt=""
                  />
                </div>
                <div className="services-content">
                  <h2 className="title">
                    <a href="/services/services-details">Wellpoint Dewatering</a>
                  </h2>
                  <h2 className="number">03</h2>
                </div>
                <div className="services-overlay-content">
                  <h2 className="title">
                    <a href="/services/services-details">Wellpoint Dewatering</a>
                  </h2>
                  <p>
                    Our wellpoint dewatering service uses a series of small
                    wellpoints connected to a vacuum pump to lower the groundwater
                    level for shallow excavations.
                  </p>
                  <a href="/services/services-details" className="read-more">
                    Read More <i className="fas fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ServiceArea;
