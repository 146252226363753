import React from "react";

function MainWid() {
  return (
    <section className="team-area pt-65 pb-70">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-title text-center mb-60">
              <span className="sub-title">Professional Team</span>
              <h2 className="title">Our Team Members</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-xl-3 col-lg-4 col-md-6 col-sm-10">
            <div className="team-item">
              <div className="team-thumb">
                <a href="/team-details">
                  <img
                    src={require(`../../assets/img/team/team_img01.jpg`)}
                    alt=""
                  />
                </a>
                <div className="team-social">
                  <ul className="list-wrap">
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
             
              <div className="team-content">
                <h2 className="title">
                  <a href="/team-details">Name</a>
                </h2>
                <span>Designation</span>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 col-sm-10">
            <div className="team-item">
              <div className="team-thumb">
                <a href="/team-details">
                  <img
                    src={require(`../../assets/img/team/team_img06.jpg`)}
                    alt=""
                  />
                </a>
                <div className="team-social">
                  <ul className="list-wrap">
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="team-content">
                <h2 className="title">
                  <a href="/team-details">Name</a>
                </h2>
                <span>Designation</span>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 col-sm-10">
            <div className="team-item">
              <div className="team-thumb">
                <a href="/team-details">
                  <img
                    src={require(`../../assets/img/team/team_img07.jpg`)}
                    alt=""
                  />
                </a>
                <div className="team-social">
                  <ul className="list-wrap">
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="team-content">
                <h2 className="title">
                  <a href="/team-details">Name</a>
                </h2>
                <span>Designation</span>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 col-sm-10">
            <div className="team-item">
              <div className="team-thumb">
                <a href="/team-details">
                  <img
                    src={require(`../../assets/img/team/team_img08.jpg`)}
                    alt=""
                  />
                </a>
                <div className="team-social">
                  <ul className="list-wrap">
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="team-content">
                <h2 className="title">
                  <a href="/team-details">Name</a>
                </h2>
                <span>Designation</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainWid;
