import React from "react";
import HeaderThree from "../Layouts/Headers/HeaderThree";
import InnerPageTitle from "../Helpers/InnerPageTitle";
import FooterOne from "../Layouts/Footers/FooterOne";
import GalleryMain from "./GalleryMain";

function Gallery() {
  return (
    <>
      <HeaderThree />
      <InnerPageTitle
        title="Product Gallery"
        paths={[
          { name: "Home", path: "/" },
          { name: "Gallery", path: "/gallery" },
        ]}
      />
      <GalleryMain />
      <FooterOne />
    </>
  );
}

export default Gallery;
