import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import parse from 'html-react-parser';
import { ClipLoader } from 'react-spinners';

import abtUs from "../../Api/about";
import getProducts, { getProductsById } from "../../Api/product";
import TableComponent from "./Table";


const MainWid = () => {
  const { pId } = useParams();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [abt, setAbt] = useState('');
  const [dynamicContent, setDynamicContent] = useState({});

  const [allProducts, setAllProducts] = useState([]);

  const categorizeImages = (productData) => {
    const dynamicContent = {};

    ["h1", "h2", "h3"].forEach((section) => {
      if (productData[section] || productData[`${section.replace('h', 'desc')}`]) {
        dynamicContent[section] = {
          heading: productData[section] || '',
          description: productData[`${section.replace('h', 'desc')}`] || '',
          images: [],
        };
      }
    });


    Object.keys(productData).forEach((key) => {
      if (key.startsWith("img")) {
        const imageKey = productData[key].match(/h\d/);

        if (imageKey) {
          const section = imageKey[0];

          if (!dynamicContent[section]) {
            dynamicContent[section] = {
              heading: '',
              description: '',
              images: [],
            };
          }

          dynamicContent[section].images.push(productData[key]);
        }
      }
    });

    return dynamicContent;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productResponse = await getProductsById(pId);
        const productData = productResponse.data;
        const updatedProductData = {
          ...productData,
          img1: productData.img1?.replace("https://", "http://"),
          img2: productData.img2?.replace("https://", "http://"),
          img3: productData.img3?.replace("https://", "http://"),
        };

        setProducts([updatedProductData]);
        setDynamicContent(categorizeImages(updatedProductData));

        const abtResponse = await abtUs();
        setAbt(abtResponse.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };



    const fetchProductsData = async () => {
      try {
        const response = await getProducts();
        const allproductData = response.data;

        setAllProducts(allproductData);
      } catch (err) {
        console.error('Error fetching product names:', err.message);
      }
    };

    fetchProductsData();
    fetchData();
  }, [pId]);




  if (loading) {
    return (
      <div className="loader-container">
        <ClipLoader
          size={80}
          color="#131944"
          loading={loading}
        />
      </div>
    );
  }

  if (error) return <div>Error fetching product data: {error}</div>;

  return (
    <section className="project-details-area pt-70 pb-60">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            {products.map((product) => (
              <div key={product.id} className="project-details-wrap">
                <div className="project-details-content">
                  <h2 className="title">{product.name}</h2>
                  <p className="info-one">  {parse(product.description)} </p>
                </div>
                <div className="project-details-thum">
                  <div className="row">
                    <div className="col-lg-4 col-md-8">
                      <img src={product.img1} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {Object.keys(dynamicContent).map((key) => (
              <div key={key} className="project-details-wrap">
                <div className="project-details-content">
                  <h2 className="titl">{dynamicContent[key].heading}</h2>
                  <p className="info-one">{parse(dynamicContent[key].description)}</p>
                </div>
                <div className="project-details-thum">
                  <div className="row">
                    {dynamicContent[key].images.length > 0 ? (
                      dynamicContent[key].images.map((img, index) => (
                        <div key={ index } className="col-lg-4 col-md-8">
                          <img src={img} alt="" />
                        </div>
                      ))
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="col-lg-4">
            <aside className="project-sidebar">








              <div className="services-widget">
                <h4 className="widget-title">Other Products</h4>
                {allProducts
                  .filter((prod) => prod.id !== pId)
                  .slice(0, 3)
                  .map((prod) => (
                    <div key={prod.id} className="our-services-list">
                      <ul className="list-wrap">
                        <li>
                          <Link to={`/product-details/${prod.id}`}>
                            {prod.name}
                            <i className="fas fa-arrow-right"></i>
                          </Link>
                        </li>

                      </ul>
                    </div>
                  ))}
              </div>





              <div className="project-widget">
                <h4 className="widget-title">Need Our Help?</h4>
                <div className="project-contact">
                  <ul className="list-wrap">
                    <li>
                      <Link to={`callto:${abt.phone}`}>
                        <i className="fas fa-phone-alt"></i>

                        <Link> {abt.phone}</Link>
                      </Link>
                    </li>

                    <li>
                      <Link to={`mailto:${abt.email}`}>
                        <i className="fas fa-envelope"></i>
                        <Link>
                          {abt.email}
                        </Link>
                      </Link>
                    </li>

                    <li className="imap">
                      <i className="fas fa-map-marker-alt"></i>
                      Miller and Bauer International,
                      <br />
                      202, Venus House, 2nd Floor, <br />
                      Ludhiana 141 010, India
                    </li>
                  </ul>
                </div>
              </div>
            </aside>
          </div>
        </div>

        <TableComponent productId={pId} />
      </div>
    </section>
  );
};

export default MainWid;

