import React from "react";
import HeaderOne from "../Layouts/Headers/HeaderOne";
import Hero from "./Hero";
import FeatureArea from "./FeatureArea";
import ServiceArea from "./ServiceArea";
import AboutArea from "./AboutArea";
import ProjectArea from "./ProjectArea";
// import TeamArea from "./TeamArea";
// import TestimonialArea from "./TestimonialArea";
// import Blogs from "./Blogs";
// import NewsLetterArea from "./NewsLetterArea";
// import Gallery from "./Gallery";
import FunFact from "./FunFact";
import FooterOne from "../Layouts/Footers/FooterOne";
import Executives from "./Executives";
import NewFeatureArea from "./NewFeatureArea";


function HomeOne() {
  return (
    <>
      <HeaderOne />
      <main>
        <Hero />
        <FeatureArea />
        <AboutArea />
        <ServiceArea className="services-area" />
        <ProjectArea />
       
        {/* <Gallery/> */}
        {/* <TeamArea /> */}
        <FunFact />

     

        
        <Executives />

        <NewFeatureArea />

        
        {/* <TestimonialArea /> */}
        {/* <Blogs /> */}
        {/* <NewsLetterArea /> */}
      </main>
      <FooterOne />
    </>
  );
}

export default HomeOne;
