import axios from 'axios';
import API_BASE_URL from "./config";

const auth = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded', 
    }
});

const contactUs = async (contactUsData) => {
    try {
       
        const params = new URLSearchParams();
        for (const key in contactUsData) {
            if (contactUsData.hasOwnProperty(key)) {
                params.append(key, contactUsData[key]);
            }
        }

        const response = await auth.post('/contact-us', params);

        return response.data;
    } catch (error) {
        if (error.response && (error.response.status === 400 || error.response.status === 401)) {
            console.log(error.response);
        }
        throw error;
    }
};

export default contactUs;
