import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import parse from 'html-react-parser';

import getProducts from "../Api/product";
import getCategories from "../Api/categories";


function MainWid() {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({ id: "all", name: "All" });
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 10;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [productResponse, categoryResponse] = await Promise.all([getProducts(), getCategories()]);

        const productData = productResponse.data.map(product => ({
          ...product,
          img1: product.img1.replace('https://', 'http://'),
        }));
        setProducts(productData);

        const categoryData = [{ id: "all", name: "All" }, ...categoryResponse.data];
        setCategories(categoryData);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    fetchData();
  }, []);

  const handleCategorySelect = (event, category) => {
    event.preventDefault();
    setSelectedCategory(category);
    setCurrentPage(1);
  };

  const filteredProducts = selectedCategory.id === "all"
    ? products
    : products.filter(product => product.category_id === selectedCategory.id.toString());

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => 
    Array.from({ length: totalPages }, (_, i) => (
      <li key={i + 1} className={`page-item ${currentPage === i + 1 ? "active" : ""}`}>
        <a onClick={() => handlePageChange(i + 1)} className="page-link" href="#">
          {i + 1}
        </a>
      </li>
    ));
  

  const getCategoryCount = (categoryId) => 
    categoryId === "all" ? products.length : 
    products.filter(product => product.category_id === categoryId.toString()).length;
  

  return (
    <section className="inner-project-area pt-115 pb-90">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-title text-center mb-60">
              <span className="sub-title">Our Products</span>
              <h2 className="title">Our Latest Products</h2>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-xl-4 col-lg-6 col-md-10">
            <aside className="blog-sidebar">
              <div className="blog-widget">
                <h4 className="widget-title">Categories</h4>
                <div className="categories-list">
                  <ul className="list-wrap">
                    {categories.map((category) => (
                      <li key={category.id}>
                        <Link
                          to="#"
                          onClick={(event) => handleCategorySelect(event, category)}
                          className={selectedCategory.id === category.id ? "active" : ""}
                        >
                          {category.name} <span>({getCategoryCount(category.id)})</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </aside>
          </div>

          <div className="col-xl-8">
            <div className="row justify-content-center">
              {currentProducts.map((product) => (
                <div key={product.id} className="col-lg-6 col-md-6 col-sm-10">
                     <Link to ={`/product-details/${product.id}`}>  
                  <div className="project-item-two">
                    <div className="project-thumb-two">
                      <a href={`/product-details/${product.id}`}>
                        <img src={product.img1} alt={product.name} />
                      </a>
                    </div>
                    <div className="project-content-two">
                      <h2 className="title">
                        <a href={`/product-details/${product.id}`}>{product.name}</a>
                      </h2>
                      <Link to={`/product-details/${product.id}`} className="link-btn">
                        <i className="fas fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="pagination-wrap mt-50">
          <nav aria-label="Page navigation example">
            <ul className="pagination list-wrap">
              <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                <Link className="page-link" to="#" onClick={() => handlePageChange(currentPage - 1)}>
                  <i className="fas fa-chevron-left"></i>
                </Link>
              </li>
              {renderPageNumbers()}
              <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
                <Link className="page-link" to="#" onClick={() => handlePageChange(currentPage + 1)}>
                  <i className="fas fa-chevron-right"></i>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </section>
  );
}

export default MainWid;
