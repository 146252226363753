import React from "react";
import HeaderThree from "../Layouts/Headers/HeaderThree";
import InnerPageTitle from "../Helpers/InnerPageTitle";
import MainWid from "./MainWid";
import FooterOne from "../Layouts/Footers/FooterOne";
import Hero from "../HomeThree/Hero";

import AboutArea from "../HomeTwo/AboutArea";

function index() {
  return (
    <>

      <HeaderThree />

      {/* <AboutArea /> */}

      {/* <Hero/>  */}


      <InnerPageTitle
        title="Contact Us"
        paths={[
          { name: "Home", path: "/" },
          { name: "Contact", path: "/contact" },
        ]}

      />



      <MainWid />
      <FooterOne />
    </>
  );
}

export default index;
