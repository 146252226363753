import React from "react";

function MainWid() {
  return (
    <section className="team-details-area pt-120 pb-120">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="team-details-thumb">
              <img
                src={require(`../../assets/img/team/team_details01.jpg`)}
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="team-details-info">
              <div className="info-content-top">
                <h3 className="title">Name</h3>
                <span>Designation</span>
              </div>
              <ul className="list-wrap team-info">
                <li>
                  <span>
                    <i className="fas fa-toolbox"></i>Experience:
                  </span>
                  15 Years
                </li>
                <li>
                  <span>
                    <i className="fas fa-envelope"></i>E-mail:
                  </span>
                  abc@example.com
                </li>
                <li>
                  <span>
                    <i className="fas fa-phone-alt"></i>Phone:
                  </span>
                  +1-800-456-478-00
                </li>
                <li>
                  <span>
                    <i className="fas fa-map-marker-alt"></i>Location:
                  </span>
                  380 St Kilda Road,  <br /> VIC 3004, India
                </li>
              </ul>
              <div className="team-details-social">
                <h6 className="title">Follow Me:</h6>
                <ul className="list-wrap">
                  <li>
                    <a href="#">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="team-details-content">
              <h3 className="title">Personal Experience and Skills</h3>
              <p className="info-one">
                Text about employee
              </p>


              {/* <p className="info-two">
               
              </p> */}


              {/* <div className="progress-wrap">
                <div className="progress-item">
                  <h5 className="title">Software Development</h5>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "88%" }}
                      aria-valuenow="88"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                    <span>88%</span>
                  </div>
                </div>
                <div className="progress-item">
                  <h5 className="title">UL / UX Design</h5>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "80%" }}
                      aria-valuenow="80"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                    <span>80%</span>
                  </div>
                </div>
                <div className="progress-item">
                  <h5 className="title">Web Development</h5>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "95%" }}
                      aria-valuenow="95"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                    <span>95%</span>
                  </div>
                </div>
                <div className="progress-item">
                  <h5 className="title">SEO Optimization</h5>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "90%" }}
                      aria-valuenow="90"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                    <span>90%</span>
                  </div>
                </div>
              </div> */}


{/* 
              <div className="guidelines-wrap">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="guidelines-img">
                      <img
                        src={require(`../../assets/img/team/team_details02.jpg`)}
                        alt=""
                      />
                    </div>
                  </div> 



                  <div className="col-lg-6">
                    <div className="guidelines-content">
                      <h4 className="title">Career Guidelines</h4>
                      <p>
                        Sample
                      </p>
                      <ul className="list-wrap">
                        <li>Sample</li>
                        <li>
                        Sample
                        </li>
                        <li>
                        Sample
                        </li>
                      
                      </ul>
                    </div>
                  </div>
              </div>
              </div> */}




            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainWid;
