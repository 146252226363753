import React from "react";
import HeaderThree from "../Layouts/Headers/HeaderThree";
import FooterOne from "../Layouts/Footers/FooterOne";
import MainWid from "./MainWid";
import Hero from "../HomeFour/Hero"
// import InnerPageTitle from "../Helpers/InnerPageTitle";

function Project() {
  return (
    <>
      <HeaderThree />

       <Hero/>
      {/* <InnerPageTitle
        title="Product"
        paths={[
          { name: "Home", path: "/" },
          { name: "Product", path: "/product" },
        ]}
      /> */}
      <MainWid />
      <FooterOne />
    </>
  );
}

export default Project;
