import React from "react";

function GalleryMain() {
  return (
    <section className="inner-project-area pt-115 pb-90">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-title text-center mb-60">
              <span className="sub-title">PRODUCTS GALLERY</span>
              <h2 className="title">Our Products</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 col-sm-10">
            <div className="project-item-two">
              <div className="project-thumb-two">
                <a href="/product-details">
                  <img
                    src={require(`../../assets/img/project/h3_project_img01.jpg`)}
                    alt=""
                  />
                </a>
              </div>
              <div className="project-content-two">
                {/* <span>Product </span> */}
                <h2 className="title">
                  <a href="/product-details">Product Name </a>
                </h2>
                <a href="/product-details" className="link-btn">
                  <i className="fas fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
         
          <div className="col-lg-4 col-md-6 col-sm-10">
            <div className="project-item-two">
              <div className="project-thumb-two">
                <a href="/product-details">
                  <img
                    src={require(`../../assets/img/project/h3_project_img05.jpg`)}
                    alt=""
                  />
                </a>
              </div>
              <div className="project-content-two">
                {/* <span>Product </span> */}
                <h2 className="title">
                  <a href="/product-details">Product Name </a>
                </h2>
                <a href="/product-details" className="link-btn">
                  <i className="fas fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-10">
            <div className="project-item-two">
              <div className="project-thumb-two">
                <a href="/product-details">
                  <img
                    src={require(`../../assets/img/project/h3_project_img06.jpg`)}
                    alt=""
                  />
                </a>
              </div>
              <div className="project-content-two">
                {/* <span>Product </span> */}
                <h2 className="title">
                  <a href="/product-details">Product Name </a>
                </h2>
                <a href="/product-details" className="link-btn">
                  <i className="fas fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GalleryMain;
