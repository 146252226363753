import React from "react";

function AboutArea({ className }) {
  return (
    <section className={`about-area pb-120 ${className || ""}`}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-7 col-lg-6 order-0 order-lg-2">
            <div className="about-img-wrap">
              <img
                src={require(`../../assets/img/download/pro.jpg`)}
                alt=""
                className="wow fadeInRight"
                data-wow-delay=".4s"
              />
              <img
                src={require(`../../assets/img/download/service-2.jpg`)}
                alt=""
                className="wow fadeInRight"
                data-wow-delay=".2s"
              />
              <div className="about-experiences-wrap">
                <div className="experiences-item">
                  <div className="icon">
                    <img
                      src={
                        require(`../../assets/img/icon/about_icon01.svg`)
                          .default
                      }
                      alt=""
                    />
                  </div>
                  <div className="content">
                    <h6 className="title">
                      Innovative and reliable products
                    </h6>
                  </div>
                </div>
                <div className="experiences-item">
                  <div className="icon">
                    <img
                      src={
                        require(`../../assets/img/icon/about_icon02.svg`)
                          .default
                      }
                      alt=""
                    />
                  </div>
                  <div className="content">
                    <h6 className="title">
                      Professional and experienced staff
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="about-content">
              <div className="section-title mb-25 tg-heading-subheading animation-style3">
                <span className="sub-title tg-element-title">
                  About Our Company
                </span>
                <h2 className="title tg-element-title">
                  We’re Committed to providing you Excellent Services
                </h2>
              </div>
              <p>
                At Miller & Bauer International, we strive to deliver products that are not only innovative and reliable but also tailored to meet the specific needs of our global clientele. Join us as we continue to set the standard for quality and excellence in our industry.

              </p>
              <div className="about-list">
                <ul className="list-wrap">
                  <li>
                    <i className="fas fa-check"></i>  More than 20 years of industry experience.
                  </li>
                  <li>
                    <i className="fas fa-check"></i>We proudly export to the USA, UK, Canada, Australia, and the Middle East.
                  </li>
                  <li>
                    <i className="fas fa-check"></i>Our team ensures exceptional quality and customer satisfaction.
                  </li>
                </ul>
              </div>
              <a href="/about" className="btn">
                Learn More
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutArea;
