

import axios from 'axios';
import API_BASE_URL from './config';






const auth = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',

  },
});



const getCategories = async () => {
  try {
    const response = await auth.get('/category');

    return response.data;

  } catch (error) {
    if (error.response && (error.response.status === 400 || error.response.status === 401)) {
      console.log(error.response);
    }
    throw error;
  }
};

export default getCategories;


