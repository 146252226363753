import React, { useState } from "react";

function Executives() {
  const [tab, setTab] = useState(1);

  return (
    <section className="services-area-three">
      <div
        className="services-bg jarallax"
        style={{
          backgroundImage: `url(${require("../../assets/img/download/asd.jpg")})`,
        }}
      ></div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="section-title text-center white-title mb-60">
              <span className="sub-title">MEET THE POWER BEHIND OUR SUCCESS</span>
              <h2 className="title"> OUR CHAIRMAN </h2>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-xl-10">
            <div className="services-nav-wrap">

              <div className="tab-content" id="myTabContent">
                <div>
                  <div className="services-item-three">
                    <div className="services-thumb-three">
                      <img
                        src={require(`../../assets/img/download/chairmnn.jpg`)}
                        alt="CEO"
                      />
                    </div>
                    <div className="services-content-three">
                      <div className="section-title mb-30">
                        <h2 className="title">
                          R. SIDHARDHAN
                        </h2>
                      </div>
                      <p className="justified-text">
                        R. Sidhardhan is a specialist in international business with over 40 years of extensive experience in manufacturing, marketing, and management. He focuses on supplying products for the de-watering, irrigation, and oil field industries. His product range includes hose couplings, fittings and accessories, flanges, brass valves, shell ferrules, swaging rings, and more.

                        Throughout his career, R. Sidhardhan has traveled extensively across numerous countries, establishing strong business relationships with many overseas buyers. He is renowned for delivering excellent quality materials, ensuring timely deliveries, offering competitive pricing, and providing exceptional after-sales service. His commitment to these principles has solidified his reputation as a trusted and reliable business partner in the international market.
                      </p>




                      {/* <div className="services-list">
                        <ul className="list-wrap">
                          <li>
                            <i className="fas fa-check-circle"></i>Commitment to Excellence
                          </li>
                          <li>
                            <i className="fas fa-check-circle"></i>Innovative Solutions
                          </li>
                          <li>
                            <i className="fas fa-check-circle"></i>Sustainable Practices
                          </li>
                          <li>
                            <i className="fas fa-check-circle"></i>Client-Centric Approach
                          </li>
                        </ul>
                      </div> */}



                      {/* <a href="/about" className="btn">
                        More details
                      </a> */}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Executives;
