import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

function HeaderThree({ className = "menu-area-three" }) {
  const location = useLocation();
  const currentPath = location.pathname;


  const [drawer, setDrawer] = useState(false);
  useEffect(() => {
    if (drawer) {
      document.body.classList.add("mobile-menu-visible");
    } else {
      document.body.classList.remove("mobile-menu-visible");
    }
  }, [drawer]);
  return (
    <header>
      <div id="header-fixed-height"></div>
      <div id="sticky-header" className={`menu-area  ${className || ""}`}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                onClick={() => setDrawer(!drawer)}
                className="mobile-nav-toggler"
              >
                <i className="fas fa-bars"></i>
              </div>
              <div className="menu-wrap">
                <nav className="menu-nav">
                  <div className="logo">
                    <a href="/">
                      <img className="hlo"
                        src={require(`../../../assets/img/download/logo.png`)}
                        alt="Logo"
                      />
                    </a>
                  </div>
                  <div className="navbar-wrap main-menu d-none d-lg-flex">
                    <ul className="navigation" style={{ margin: '0 auto' }}>



                      <li className={currentPath === '/' ? 'active' : ''}>
                        {/* <a href="/">Home</a> */}
                        <Link to="/">Home</Link>
                      </li>
                      <li className={currentPath === '/about' ? 'active' : ''}>
                        {/* <a href="/about">About Us</a> */}
                        <Link to="/about">About Us</Link>
                      </li>
                      <li className={currentPath === '/product' ? 'active' : ''}>
                        {/* <a href="/product">Products</a> */}
                        <Link to="/product">Products</Link>
                      </li>
                      <li className={currentPath === '/services' ? 'active' : ''}>
                        {/* <a href="/services">Services</a> */}
                        <Link to="/services">Services</Link>
                      </li>
                      <li className={currentPath === '/contact' ? 'active' : ''}>
                        {/* <a href="/contact">Contact Us</a> */}
                        <Link to="/contact">Contact Us </Link>
                      </li>
                      {/* <li className={currentPath === '/logicfusion' ? 'active' : ''}>
                        <a href="/logicfusion">Logic Fusion Group </a>
                        <Link to="/logicfusion">Logic Fusion Group </Link>
                      </li> */}



                      {/* <li className={currentPath === '/logicfusion' ? 'active' : ''}>
                        <a href="/logicfusion">Logic Fusion Group</a>
                      </li> */}








                    </ul>
                  </div>
                  <div className="header-action d-none d-md-block">
                    <ul className="list-wrap">
                      <li className="header-btn hquo">
                        {/* <a href="/quote" className="btn">
                          Get a Quote
                        </a> */}
                        <Link to="/quote" className="btn">Get a Quote</Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>

              <div className="mobile-menu">
                <nav className="menu-box">
                  <div onClick={() => setDrawer(!drawer)} className="close-btn">
                    <i className="fas fa-times"></i>
                  </div>
                  <div className="nav-logo">
                    <a href="/">
                      <img
                        src={require(`../../../assets/img/download/logo.png`)}
                        alt="Logo"
                      />
                    </a>
                  </div>
                  <div className="menu-outer">
                    <ul className="navigation">
                      <li>
                        <a href="/">Home</a>




                      </li>
                      <li>
                        <a href="/about">About us</a>
                      </li>



                      <li >
                        <a href="/product">Products</a>

                      </li>




                      <li>
                        <a href="/services">Services </a>
                      </li>



                      <li>
                        <a href="/contact">Contact Us </a>
                      </li>


                      {/* <li >
                        <a href="/logicfusion">Logic Fusion Group</a>

                      </li> */}



                    </ul>
                  </div>


                  {/*                   
                  <div className="social-links">
                    <ul className="clearfix list-wrap">
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                    </ul>
                  </div> */}




                </nav>
              </div>
              <div className="menu-backdrop"></div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default HeaderThree;
