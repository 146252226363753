import React from "react";
import FooterOne from "../Layouts/Footers/FooterOne";
import HeaderThree from "../Layouts/Headers/HeaderThree";
import AboutArea from "../HomeTwo/AboutArea";
import WorkArea from "./WorkArea";
import HistoryArea from "./HistoryArea";
import Hero from "../HomeTwo/Hero";
// import BrandAreaWid from "../Common/BrandAreaWid";
// import InnerPageTitle from "../Helpers/InnerPageTitle";
// import TeamArea from "../HomeOne/TeamArea";
// import TestimonialArea from "./TestimonialArea";

function index() {
  return (
    <>
      <HeaderThree />
      <Hero />
      {/* <InnerPageTitle
        title="About Us"
        paths={[
          { name: "Home", path: "/" },
          { name: "About", path: "/about" },
        ]}
      /> */}
      <AboutArea className="pt-120" />
      <WorkArea />
      <HistoryArea />
      {/* <div
        className="area-bg-five"
        style={{
          backgroundImage: `url(${require("../../assets/img/bg/area_bg05.jpg")})`,
        }}
      >
       
        <TestimonialArea />
      </div> */}
      {/* <BrandAreaWid /> */}
      <FooterOne />
    </>
  );
}

export default index;
