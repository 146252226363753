import React from "react";

function AboutArea() {
  return (
    <section
      className="about-area-two parallax about-bg"
      style={{
        backgroundImage: `url(${require("../../assets/img/bg/about_bg.jpg")})`,
      }}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 order-0 order-lg-2">
            <div className="about-img-wrap-two">
              <ul className="list-wrap">
                <li>
                  <div
                    className="year-experience-wrap wow fadeInDown"
                    data-wow-delay=".2s"
                  >
                    <div className="icon">
                      <img
                        src={
                          require(`../../assets/img/icon/h2_about_icon03.svg`)
                            .default
                        }
                        alt=""
                      />
                    </div>
                    <h2 className="title">
                      20 +  Years <span>Experience</span>
                    </h2>
                  </div>
                </li>
                <li>
                  <img
                    src={require(`../../assets/img/download/pro.jpg`)}
                    alt=""
                    className="wow fadeInLeft"
                    data-wow-delay=".4s"
                  />
                </li>
              </ul>
              <ul className="list-wrap">
                <li>
                  <img
                    src={require(`../../assets/img/download/re.jpg`)}
                    alt=""
                    className="wow fadeInRight"
                    data-wow-delay=".2s"
                  />
                </li>
                <li>
                  <img
                    src={require(`../../assets/img/download/asc.webp`)}
                    alt=""
                    className="wow fadeInUp"
                    data-wow-delay=".4s"
                  />
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content-two">
              <div className="section-title mb-30">
                <span className="sub-title">About Our Company</span>
                <h2 className="title">We’re Committed to providing you Excellent Services</h2>
              </div>
              <p>
                Discover Miller & Bauer International, your global partner for top-tier industrial solutions! As a Government of India Recognized Star Export House, we manufacture and export high-quality hose couplings, fittings, accessories, dewatering pumps, and rubber-related products. Serving industries like dewatering, construction, and oil fields, our products meet international standards and are trusted worldwide.
              </p>
              <div className="about-list-two">
                <ul className="list-wrap">
                  <li className="list-item">
                    <div className="icon">
                      <img
                        src={
                          require(`../../assets/img/icon/h2_about_icon01.svg`)
                            .default
                        }
                        alt=""
                      />
                    </div>
                    <div className="content">
                      <h5 className="title">Unmatched Quality Products
                      </h5>
                    </div>
                  </li>
                  <li className="list-item">
                    <div className="icon">
                      <img
                        src={
                          require(`../../assets/img/icon/h2_about_icon02.svg`)
                            .default
                        }
                        alt=""
                      />
                    </div>
                    <div className="content">
                      <h5 className="title">
                        Extensive Experience And Global Reach
                      </h5>
                    </div>
                  </li>
                </ul>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="about-shape-wrap">
        <ul className="list-wrap">
          <li>
            <img
              src={require(`../../assets/img/images/about_shape01.png`)}
              className="layer"
              data-depth="0.3"
              alt=""
            />
          </li>
          <li>
            <img
              src={require(`../../assets/img/images/about_shape02.png`)}
              className="layer"
              data-depth="0.3"
              alt=""
            />
          </li>
          <li>
            <img
              src={require(`../../assets/img/images/about_shape03.png`)}
              className="layer"
              data-depth="0.2"
              alt=""
            />
          </li>
        </ul>
      </div>
    </section>
  );
}

export default AboutArea;
