import React, { useState, useEffect } from "react";
import contactUs from "../Api/contact";
import abtUs from "../Api/about";

import { Link } from "react-router-dom";


function MainWid() {
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    email: "",
    mobile: "",
    subject: "",
    message: ""
  });

  const [errors, setErrors] = useState({});

  const [successMessage, setSuccessMessage] = useState("");
  const [abt, setAbt] = useState({});
  const [loading, setLoading] = useState(true);

  const validate = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "First name is required";
    if (!formData.surname) newErrors.surname = "Last name is required";
    if (!formData.email) newErrors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = "Email is invalid";
    if (!formData.mobile) newErrors.mobile = "Mobile number is required";
    else if (!/^\d{10}$/.test(formData.mobile)) newErrors.mobile = "Mobile number is invalid";
    if (!formData.subject) newErrors.subject = "Subject is required";
    if (!formData.message) newErrors.message = "Message is required";
    return newErrors;
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await contactUs(formData);
        setSuccessMessage("Your message has been sent successfully!");
        setFormData({
          name: "",
          surname: "",
          email: "",
          mobile: "",
          subject: "",
          message: ""
        });
        setTimeout(() => {
          setSuccessMessage("");
        }, 3000);
        console.log(response);
      } catch (error) {
        setSuccessMessage("");
        setErrors({ submit: "Failed to send message." });
        setTimeout(() => {
          setErrors({});
        }, 3000);
        console.error(error);
      }
    }
  };

  useEffect(() => {
    const fetchAbtData = async () => {
      try {
        const abtResponse = await abtUs();
        setAbt(abtResponse.data);
      } catch (error) {
        setErrors({ fetch: "Failed to fetch contact information." });
      } finally {
        setLoading(false);
      }
    };

    fetchAbtData();
  }, []);

  return (
    <section className="contact-area pt-60 pb-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-10">
            <div
              className="contact-form-wrap"
              style={{
                backgroundImage: `url(${require("../../assets/img/images/contact_form_bg.jpg")})`,
              }}
            >
              <h2 className="title">Contact With Us</h2>
              <p>Send us a message and we&apos;ll respond as soon as possible</p>

              <form className="contact-form" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input
                        id="name"
                        type="text"
                        placeholder="First Name*"
                        value={formData.name}
                        onChange={handleChange}
                      />
                      {errors.name && <small className="error">{errors.name}</small>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input
                        id="surname"
                        type="text"
                        placeholder="Last Name*"
                        value={formData.surname}
                        onChange={handleChange}
                      />
                      {errors.surname && <small className="error">{errors.surname}</small>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input
                        id="email"
                        type="email"
                        placeholder="Email Address*"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      {errors.email && <small className="error">{errors.email}</small>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input
                        id="mobile"
                        type="text"
                        placeholder="Mobile Number*"
                        value={formData.mobile}
                        onChange={handleChange}
                      />
                      {errors.mobile && <small className="error">{errors.mobile}</small>}
                    </div>
                  </div>
                </div>
                <div className="form-grp">
                  <input
                    id="subject"
                    type="text"
                    placeholder="Subject"
                    value={formData.subject}
                    onChange={handleChange}
                  />
                  {errors.subject && <small className="error">{errors.subject}</small>}
                </div>
                <div className="form-grp">
                  <textarea
                    id="message"
                    placeholder="Your Message here"
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>
                  {errors.message && <small className="error">{errors.message}</small>}
                </div>

                {successMessage && <div className="alert alert-success">{successMessage}</div>}
                {errors.submit && <div className="alert alert-danger">{errors.submit}</div>}

                <button className="btn" type="submit">
                  Send Message
                </button>
              </form>
            </div>
          </div>


          <div className="col-xl-6 col-lg-10">
            <div className="contact-info-wrap">
              <h2 className="title">Need Any Help?</h2>
              <p>Call us or message and we&apos;ll respond as soon as possible</p>
              {loading ? (
                <p>Loading...</p>
              ) : (
                <div className="list-wrap">
                  <div className="nlwrap">



                    <a href={`callto:${abt.phone}`} className="contact-info-link">
                      <div className="contact-info-item">
                        <div className="icon">
                          <i className="fas fa-mobile-alt"></i>
                        </div>
                        <div className="content">
                          {abt.phone}
                        </div>
                      </div>
                    </a>


                    <a href={`mailto:${abt.email}`}>
                      <div className="contact-info-item">
                        <div className="icon">
                          <i className="fas fa-envelope"></i>
                        </div>
                        <div className="content">
                          {abt.email}
                        </div>
                      </div>
                    </a>
                  </div>



                  <div className="contact-info-item">
                    <div className="icon">
                      <i className="fas fa-map-marker-alt"></i>
                    </div>
                    <div className="content">

                      <p>
                        Miller and Bauer International,<br />
                        155, Venus House, 2nd Floor,<br />
                        Street No.9, Jeevan Nagar , <br />
                        Focal Point , Ludhiana
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {errors.fetch && <p className="error">{errors.fetch}</p>}
            </div>


            <div id="contact-map">
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4071.7051262819814!2d75.9135214693323!3d30.889954991598387!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391a9d24c29ae1fb%3A0xeee920f1c419ffd9!2sMILLER%20%26%20BAUER%20INTERNATIONAL!5e0!3m2!1sen!2sin!4v1715840636232!5m2!1sen!2sin"
                allowFullScreen
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainWid;
