import React from "react";
import HeaderThree from "../Layouts/Headers/HeaderThree";
import InnerPageTitle from "../Helpers/InnerPageTitle";

import Quote from "./Quote";
import FooterOne from "../Layouts/Footers/FooterOne";


function Services() {
  return (
    <>
      <HeaderThree />
      <main>

          
     


        <Quote />



      </main>
      <FooterOne />
    </>
  );
}

export default Services;
