import React from "react";

function NewFeatureArea() {
    return (
        <section
            className="features-area-two features-bg"
            style={{
                backgroundImage: `url(${require("../../assets/img/bg/features_bg.jpg")})`,
            }}
        >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-4 col-lg-6 col-md-8">
                        <div
                            className="features-item-two wow fadeInUp"
                            data-wow-delay=".2s"
                        >
                            <div className="features-item-shape">
                                <svg
                                    viewBox="0 0 451 518"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M0 15.5706C0 12.3122 2.60044 9.64945 5.85786 9.57226L389.414 0.483096C423.541 -0.32561 451.466 27.4618 450.825 61.5922L442.369 512.113C442.308 515.382 439.64 518 436.37 518H6C2.6863 518 0 515.314 0 512V15.5706Z"
                                        fill="currentcolor"
                                    />
                                </svg>
                            </div>
                            <div className="features-item-top">
                                <div className="features-icon-two">
                                    <img
                                        src={
                                            require(`../../assets/img/icon/features_icon01.svg`)
                                                .default
                                        }
                                        alt=""
                                    />
                                </div>
                                <div className="features-content-two">
                                    <h2 className="title">Industry Expertise</h2>
                                    <p>
                                    With over a decade of experience and highly qualified promoters with over 20 years in similar industries, we offer unmatched expertise and insight into our products and their applications.
                                    </p>
                                </div>
                            </div>
                            <div className="features-thumb">
                                <a href="/services/services-details">
                                    <img
                                        src={require(`../../assets/img/download/pro.jpg`)}
                                        alt=""
                                    />
                                </a>
                                <a href="/services/services-details" className="link-btn">
                                    <img
                                        src={
                                            require(`../../assets/img/icon/right_arrow02.svg`).default
                                        }
                                        alt=""
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-8">
                        <div
                            className="features-item-two wow fadeInUp"
                            data-wow-delay=".4s"
                        >
                            <div className="features-item-shape">
                                <svg
                                    viewBox="0 0 451 518"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M0 15.5706C0 12.3122 2.60044 9.64945 5.85786 9.57226L389.414 0.483096C423.541 -0.32561 451.466 27.4618 450.825 61.5922L442.369 512.113C442.308 515.382 439.64 518 436.37 518H6C2.6863 518 0 515.314 0 512V15.5706Z"
                                        fill="currentcolor"
                                    />
                                </svg>
                            </div>
                            <div className="features-item-top">
                                <div className="features-icon-two">
                                    <img
                                        src={
                                            require(`../../assets/img/icon/features_icon02.svg`)
                                                .default
                                        }
                                        alt=""
                                    />
                                </div>
                                <div className="features-content-two">
                                    <h2 className="title">Diverse Product Range</h2>
                                    <p>
                                    From hose couplings and accessories to advanced centrifugal self-priming pumps, we offer a comprehensive suite of products to meet diverse industrial needs.

                                    </p>
                                </div>
                            </div>
                            <div className="features-thumb">
                                <a href="/services/services-details">
                                    <img
                                        src={require(`../../assets/img/download/npr.jpg`)}
                                        alt=""
                                    />
                                </a>
                                <a href="/services/services-details" className="link-btn">
                                    <img
                                        src={
                                            require(`../../assets/img/icon/right_arrow02.svg`).default
                                        }
                                        alt=""
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-8">
                        <div
                            className="features-item-two wow fadeInUp"
                            data-wow-delay=".6s"
                        >
                            <div className="features-item-shape">
                                <svg
                                    viewBox="0 0 451 518"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M0 15.5706C0 12.3122 2.60044 9.64945 5.85786 9.57226L389.414 0.483096C423.541 -0.32561 451.466 27.4618 450.825 61.5922L442.369 512.113C442.308 515.382 439.64 518 436.37 518H6C2.6863 518 0 515.314 0 512V15.5706Z"
                                        fill="currentcolor"
                                    />
                                </svg>
                            </div>
                            <div className="features-item-top">
                                <div className="features-icon-two">
                                    <img
                                        src={
                                            require(`../../assets/img/icon/features_icon03.svg`)
                                                .default
                                        }
                                        alt=""
                                    />
                                </div>
                                <div className="features-content-two">
                                    <h2 className="title">Innovation</h2>
                                    <p>
                                    We continually invest in research and development to deliver innovative solutions that address evolving industry challenges and enhance operational efficiency.

                                    </p>
                                </div>
                            </div>
                            <div className="features-thumb">
                                <a href="/services/services-details">
                                    <img
                                        src={require(`../../assets/img/download/exp.png`)}
                                        alt=""
                                    />
                                </a>
                                <a href="/services/services-details" className="link-btn">
                                    <img
                                        src={
                                            require(`../../assets/img/icon/right_arrow02.svg`).default
                                        }
                                        alt=""
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="more-btn text-center mt-20">
          <a href="/services" className="btn">
            More Services
          </a>
        </div> */}
            </div>
        </section>
    );
}

export default NewFeatureArea;
