import React from "react";
import SliderCom from "../Helpers/SliderCom";

function TestimonialArea({ className }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    arrows: false,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <section
      className={`inner-testimonial-area parallax  pb-120 ${className || ""}`}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6">
            <div className="section-title text-center mb-50">
              <span className="sub-title">Testimonials</span>
              <h2 className="title"> Our Client Feedback</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="testimonial-inner">
              <div className="testimonial-active-two position-relative">
                <SliderCom settings={settings}>
                  <div className="testimonial-item-two">
                    <div className="testimonial-avatar-two">
                      <img
                        src={require(`../../assets/img/download/team-1.jpg`)}
                        alt=""
                      />
                    </div>
                    <div className="testimonial-content-two">
                      <div className="content-top">
                        <div className="icon">
                          <i className="fas fa-quote-left"></i>
                        </div>
                        <div className="rating">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                        </div>
                      </div>
                      <p>
                        Miller and Bauer provided exceptional dewatering services for our construction project. Their team was responsive, professional, and delivered results beyond our expectations. Highly recommend!
                      </p>
                      <div className="content-bottom">
                        <h4 className="title">Darrell Steward</h4>
                        <span>Site Engineer </span>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item-two">
                    <div className="testimonial-avatar-two">
                      <img
                        src={require(`../../assets/img/download/team1-1.jpg`)}
                        alt=""
                      />
                    </div>
                    <div className="testimonial-content-two">
                      <div className="content-top">
                        <div className="icon">
                          <i className="fas fa-quote-left"></i>
                        </div>
                        <div className="rating">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                        </div>
                      </div>
                      <p>
                        Miller and Bauer provided exceptional dewatering services for our construction project. Their team was efficient, professional, and responsive throughout the process. I highly recommend their expertise for any water management needs
                      </p>
                      <div className="content-bottom">
                        <h4 className="title">Aaron</h4>
                        <span>Project Manager</span>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item-two">
                    <div className="testimonial-avatar-two">
                      <img
                        src={require(`../../assets/img/download/team-1-6.webp`)}
                        alt=""
                      />
                    </div>
                    <div className="testimonial-content-two">
                      <div className="content-top">
                        <div className="icon">
                          <i className="fas fa-quote-left"></i>
                        </div>
                        <div className="rating">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                        </div>
                      </div>
                      <p>
                        I highly recommend Miller and Bauer for their professional services.
                      </p>
                      <div className="content-bottom">
                        <h4 className="title">Jacob</h4>
                        <span>Manager</span>
                      </div>
                    </div>
                  </div>
                </SliderCom>
              </div>
            </div>
          </div>
        </div>
        <div className="testimonial-avatar-wrap">
          <ul className="list-wrap">
            <li>
              <img
                src={require(`../../assets/img/download/team1-1.jpg`)}
                className="layer"
                data-depth="0.1"
                alt=""
              />
            </li>
            <li>
              <img
                src={require(`../../assets/img/download/testi_2_1.png`)}
                className="layer"
                data-depth="0.2"
                alt=""
              />
            </li>
            <li>
              <img
                src={require(`../../assets/img/download/team-1-6.webp`)}
                className="layer"
                data-depth="0.05"
                alt=""
              />
            </li>
            <li>
              <img
                src={require(`../../assets/img/download/testi_1_3.jpg`)}
                className="layer"
                data-depth="0.2"
                alt=""
              />
            </li>
            <li>
              <img
                src={require(`../../assets/img/download/img-p.jpg`)}
                className="layer"
                data-depth="0.05"
                alt=""
              />
            </li>
            <li>
              <img
                src={require(`../../assets/img/download/team-1.jpg`)}
                className="layer"
                data-depth="0.1"
                alt=""
              />
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default TestimonialArea;
