import React, { useEffect, useState } from "react";

import getProducts from "../Api/product";

import { Link } from "react-router-dom";
import parse from 'html-react-parser';

function ProjectArea() {

  const [products, setProducts] = useState([]);


  useEffect(() => {
    const fetchProductsData = async () => {
      try {
        const response = await getProducts();
        const productData = response.data;
        const updatedProductData = productData.map(product => ({
          ...product,
          img1: product.img1.replace('https://', 'http://')
        }));
        setProducts(updatedProductData);
      } catch (error) {
        console.error('Error fetching product data:', error.message);
      }
    };

    fetchProductsData();
  }, []);


  const imageHandler = () => {
    const els = document.querySelectorAll(".collapse-anim");
    els.forEach((item) => {
      const height = item.scrollHeight;
      item.style.setProperty("--max-height", `${height}px`);
    });
  };
  return (
    <section
      className="project-area project-bg"
      style={{
        backgroundImage: `url(${require("../../assets/img/bg/project_bg.jpg")})`,
      }}
    >
      <div className="container">
        <div className="row align-items-end">
          <div className="col-lg-8">
            <div className="section-title white-title mb-50 tg-heading-subheading animation-style3">
              <span className="sub-title tg-element-title">
                Latest Products
              </span>
              <h2 className="title tg-element-title">
                Explore Our Latest Products
              </h2>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="view-all-btn text-end mb-50">
              <a href="/product" className="btn">
                View All Products
              </a>
            </div>
          </div>
        </div>

        <div className="row">

          {products.slice(0, 4).map((product) => (
            <div key={product.id} className="col-lg-12">
              <Link to={`/product-details/${product.id}`}>
                <div className="project-item wow fadeInUp" data-wow-delay=".2s">
                  <div className="project-thumb collapse-anim">
                    <img
                      src={product.img1} alt={product.name}
                      onLoad={imageHandler}

                    />
                  </div>
                  <div className="project-content">
                    <div className="left-side-content">
                      {/* <span>Products V1</span> */}
                      <h2 className="title">
                        <a href={`/product-details/${product.id}`}>
                          {product.name}
                        </a>
                      </h2>
                      <p className="collapse-anim">
                        {parse(product.description)}
                      </p>
                    </div>
                    <div className="project-link">
                      <a href={`/product-details/${product.id}`}>
                        <i className="fas fa-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>

              </Link>

            </div>

          ))}
        </div>
      </div>
    </section>
  );
}

export default ProjectArea;



// src={require(`../../assets/img/project/project_img01.jpg`)}